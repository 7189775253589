<template>
	<div>
		<!-- 首页固定区 -->
		<div class="index-wrap" :style="{ background: backgroundColor }" v-if="!pc_layout">
			<div class="index">
				<div class="banner">
					<el-carousel height="500px" arrow="hover" v-loading="loadingAd" @change="handleChange">
						<el-carousel-item v-for="item in adList" :key="item.adv_id">
							<el-image :src="$img(item.adv_image)" fit="cover"
								@click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
				</div>
				<div class="news">
					<div class="login-wrap">
						<div class="avtar">
							<router-link to="/member/info">
								<img v-if="member.headimg" :src="$img(member.headimg)"
									@error="member.headimg = defaultHeadImage" />
								<img v-else :src="$img(defaultHeadImage)" />
							</router-link>
						</div>
						<div class="btn" v-if="!member">
							<router-link to="/login" class="login">{{ $lang('登录') }}</router-link>
							<router-link to="/register" class="register">{{ $lang('注册') }}</router-link>
						</div>
						<div class="memeber-name" v-else>{{ member.nickname }}</div>
					</div>
					<div class="notice-wrap">
						<div class="notice">
							<div class="notice-name">{{ $lang('商城资讯') }}</div>
							<router-link to="/cms/notice" class="notice-more">
								{{ $lang('更多') }}
								<i class="iconfont iconarrow-right"></i>
							</router-link>
						</div>
						<div v-for="item in noticeList" :key="item.id" class="item">
							<router-link :to="'/cms/notice-' + item.id" target="_blank" tag="a">
								<div class="notice-title">{{ $trans(item.title) }}</div>
							</router-link>
						</div>
					</div>
					<div class="xian"></div>

					<div class="server-wrap" style="display: none">
						<div class="server-title">{{ $lang('商城服务') }}</div>
						<div class="item-wrap">
							<div class="item">
								<a :href="shopApplyUrl" target="_blank">
									<i class="iconfont iconshang" />
									<span>{{ $lang('招商入驻') }}</span>
								</a>
							</div>
							<div class="item">
								<a :href="shopCenterUrl" target="_blank">
									<i class="iconfont iconshangjiazhongxin" />
									<span>{{ $lang('商家中心') }}</span>
								</a>
							</div>
							<div class="item" v-if="addonIsExit.store == 1">
								<a :href="storeUrl" target="_blank">
									<i class="iconfont iconshangjiazhongxin-" />
									<span>{{ $lang('门店管理') }}</span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="indexs-wrap" :style="{ background: backgroundColor }" v-if="pc_layout">
			<div class="indexs">
				<div class="banner">
					<el-carousel height="500px" arrow="hover" v-loading="loadingAd" @change="handleChange">
						<el-carousel-item v-for="item in adList" :key="item.adv_id">
							<el-image :src="$img(item.adv_image)" fit="cover"
								@click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
		<div class="content" v-if="!pc_layout">
			<div class="content-div" v-if="addonIsExit.coupon == 1 && couponList.length > 0 && (city.id == 0 || !city)">
				<div class="coupon">
					<div class="coupon-title">
						<p class="coupon-font">{{ $lang('领券中心') }}</p>
						<p class="coupon-en">coupon</p>
						<p class="coupon-more" @click="$router.push('/coupon')">
							<span>{{ $lang('更多') }}</span>
							<i class="iconfont iconarrow-right"></i>
						</p>
					</div>
					<ul class="coupon-list">
						<li v-for="(item, index) in couponList" :key="index">
							<p class="coupon-price ns-text-color">
								c$
								<span>{{ item.money }}</span>
							</p>
							<p class="coupon-term">满{{ item.at_least }}可用</p>
							<p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
								<span v-if="item.useState == 0">{{ $lang('立即领取') }}</span>
								<span v-else>{{ $lang('去使用') }}</span>
								<i class="iconfont iconarrow-right"></i>
							</p>
						</li>
					</ul>
				</div>
			</div>

			<div class="content-div" v-if="adLeftList.length > 0 || adRightList.length > 0">
				<div class="ad-wrap">
					<div class="ad-big">
						<div class="ad-big-img" v-for="(item, index) in adLeftList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError(index)"
								@click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
					<div class="ad-small">
						<div class="ad-small-img" v-for="(item, index) in adRightList" :key="index">
							<el-image :src="$img(item.adv_image)" fit="cover" @error="adRightImageError(index)"
								@click="$router.pushToTab(item.adv_url.url)"></el-image>
						</div>
					</div>
				</div>
			</div>

			<div class="content-div" v-if="addonIsExit.seckill == 1 && listData.length > 0 && (city.id == 0 || !city)">
				<div class="seckill-wrap">
					<div class="seckill-time">
						<div class="seckill-time-left">
							<i class="iconfont iconmiaosha1 ns-text-color"></i>
							<span class="seckill-time-title ns-text-color">{{ $lang('限时秒杀') }}</span>
							<span>{{ $trans(seckillText) }}</span>
							<count-down class="count-down" v-on:start_callback="countDownS_cb()"
								v-on:end_callback="countDownE_cb()" :currentTime="seckillTimeMachine.currentTime"
								:startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime"
								:dayTxt="'：'" :hourTxt="'：'" :minutesTxt="'：'" :secondsTxt="''"></count-down>
						</div>
						<div class="seckill-time-right" @click="$router.push('/promotion/seckill')">
							<span>{{ $lang('更多商品') }}</span>
							<i class="iconfont iconarrow-right"></i>
						</div>
					</div>
					<div class="seckill-content" @click="clickProps($event)">
						<vue-seamless-scroll :data="listData" :class-option="optionLeft" class="seamless-warp2">
							<ul class="item" :style="{ width: 250 * listData.length + 'px' }">
								<li v-for="(item, index) in listData" :key="index">
									<div class="seckill-goods" :data-obj="JSON.stringify(item)">
										<div class="seckill-goods-img" :data-obj="JSON.stringify(item)">
											<img :src="goodsImg(item.goods_image)" @error="imageError(index)"
												:data-obj="JSON.stringify(item)" />
										</div>
										<p :data-obj="JSON.stringify(item)">{{ $trans() }}</p>
										<div class="seckill-price-wrap" :data-obj="JSON.stringify(item)">
											<p class="ns-text-color" :data-obj="JSON.stringify(item)">
												c$
												<span :data-obj="JSON.stringify(item)">{{ item.seckill_price }}</span>
											</p>
											<p class="primary-price" :data-obj="JSON.stringify(item)">c${{
													$trans(item.price)
											}}
											</p>
										</div>
									</div>
								</li>
							</ul>
						</vue-seamless-scroll>
					</div>
				</div>
			</div>

			<div class="content-div " v-if="(city.id == 0 || !city)">
				<div class="floor">
					<div v-for="(item, index) in floorList" :key="index" class="floor_item">
						<floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" />
						<floor-style-2 v-if="item.block_name == 'floor-style-2'" :data="item" />
						<floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" />
					</div>
				</div>
			</div>

			<div>
				<div class="more">
					<router-link to="/list">
						<span>{{ $lang('更多') }}</span>
						<i class="iconfont iconarrow-right"></i>
					</router-link>
				</div>
				<div class="goods-info" v-if="goodsList.length">
					<div class="item" v-for="(item, index) in goodsList" :key="item.goods_id"
						@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
						<img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />
						<div class="price-wrap">
							<div class="price">
								<p>c$</p>
								{{ item.discount_price }}
							</div>
							<div class="market-price">c${{ item.market_price }}</div>
						</div>
						<div class="goods-name">{{ $trans() }}</div>
						<div class="sale-num">
							<p>{{ $trans(item.sale_num) || 0 }}</p>人付款
						</div>
						<div class="saling">
							<div class="free-shipping" v-if="item.is_free_shipping == 1">{{ $lang('包邮') }}</div>
							<div class="promotion-type" v-if="item.promotion_type == 1">{{ $lang('限时折扣') }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="floatLayer-wrap" v-if="floatLayer.is_show && city.id == 0">
				<div class="floatLayer">
					<div class="img-wrap">
						<img :src="$img(floatLayer.img_url)" @click="$router.pushToTab(floatLayer.link.url)" />
					</div>
					<i class="el-icon-circle-close" @click="closeFloat"></i>
				</div>
			</div>

			<div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
				<div class="header-search">
					<el-row>
						<el-col :span="6">
							<router-link to="/" class="logo-wrap"><img :src="$img(siteInfo.logo)" /></router-link>
						</el-col>
						<el-col :span="13">
							<div class="in-sousuo">
								<div class="sousuo-box">
									<el-dropdown @command="handleCommand" trigger="click">
										<span class="el-dropdown-link">
											{{ searchTypeText }}
											<i class="el-icon-arrow-down"></i>
										</span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="goods">{{ $lang('商品') }}</el-dropdown-item>
											<el-dropdown-item command="shop">{{ $lang('店铺') }}</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<input type="text" :placeholder="defaultSearchWords" v-model="keyword"
										@keyup.enter="search" maxlength="50" />
									<el-button type="primary" size="small" @click="search">{{ $lang('搜索') }}</el-button>
								</div>
							</div>
						</el-col>
						<el-col :span="5">
							<div class="cart-wrap">
								<router-link class="cart" to="/cart">
									<span>{{ $lang('我的购物车') }}</span>
									<el-badge v-if="cartCount" :value="cartCount" type="primary"><i
											class="iconfont icongouwuche"></i></el-badge>
									<i v-else class="iconfont icongouwuche"></i>
								</router-link>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
		</div>
		<!-- 活动 -->
		<div class="idx-detail" v-if="pc_layout">
			<div class="detail-qiye">
				<div>
					<img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161773219506.png" alt="">
					<span>{{ $lang('鲜花电商认证龙头') }}</span>
				</div>
				<div>
					<img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161783135526.png" alt="">
					<span>{{ $lang('16年品牌') }}</span>
				</div>
				<div>
					<img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161791598167.png" alt="">
					<span>{{ $lang('3小时全国送花') }}</span>
				</div>
				<div>
					<img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161800172809.png" alt="">
					<span>{{ $lang('200%退赔承诺') }}</span>
				</div>
				<div>
					<img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/02/1654161808772123.png" alt="">
					<span>{{ $lang('最近') }}{{ siteInfo.evaluate }}{{ $lang('条评价') }}</span>
				</div>
			</div>
			<div class="content-img" @click="$router.pushToTab({ path: '/list' })">
				<img src="https://sai-kuang.topcompete.com/shufa/img/2022/06/01/1654080491373819.png" alt="">
			</div>
			<div class="detail-recommend">
				<div class="recommend-box" v-for="item in listPage" :key="item.goods_id"
					@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
					<div class="recommend-box-txt">
						<p>{{ $trans(item.decs) }}</p>
						<p>{{ $trans(item.sku_name) }}</p>
						<p>￥{{ $trans(item.price) }}</p>
					</div>
					<div class="recommend-box-img">
						<img :src="$img(item.goods_image)" alt="">
					</div>
				</div>
			</div>
			<div class="idx-content">
				<div class="content-imgs">
					<div :style="{ backgroundImage: 'url(' + $img(types[0].img1) + ')' }">
						<router-link :to="{ path: '/list', query: { config_pc_id: 1 } }">
							<p>{{ $trans(types[0].title) }}</p>
							<p>{{ $trans(types[0].desc) }}</p>
							<div>
								Go >
							</div>
						</router-link>
					</div>
					<div class="content-imgs-friend">
						<div :style="{ backgroundImage: 'url(' + $img(types[1].img1) + ')' }">
							<router-link :to="{ path: '/list', query: { config_pc_id: 2 } }">
								<p>{{ $trans(types[1].title) }}</p>
								<p>{{ $trans(types[1].desc) }}</p>
								<div>
									Go >
								</div>
							</router-link>
						</div>
						<div :style="{ backgroundImage: 'url(' + $img(types[2].img1) + ')' }">
							<router-link :to="{ path: '/list', query: { config_pc_id: 3 } }">
								<p>{{ $trans(types[2].title) }}</p>
								<p>{{ $trans(types[2].desc) }}</p>
								<div>
									Go >
								</div>
							</router-link>
						</div>
					</div>
					<div :style="{ backgroundImage: 'url(' + $img(types[3].img1) + ')' }">
						<router-link :to="{ path: '/list', query: { config_pc_id: 4 } }">
							<p>{{ $trans(types[3].title) }}</p>
							<p>{{ $trans(types[3].desc) }}</p>
							<div>
								Go >
							</div>
						</router-link>
					</div>
					<div class="content-imgs-friends">
						<div>
							<div :style="{ backgroundImage: 'url(' + $img(types[4].img1) + ')' }">
								<router-link :to="{ path: '/list', query: { config_pc_id: 5 } }">
									<p>{{ $trans(types[4].title) }}</p>
								</router-link>
							</div>
							<div :style="{ backgroundImage: 'url(' + $img(types[5].img1) + ')' }">
								<router-link :to="{ path: '/list', query: { config_pc_id: 6 } }">
									<p>{{ $trans(types[5].title) }}</p>

								</router-link>
							</div>
						</div>
						<div :style="{ backgroundImage: 'url(' + $img(types[6].img1) + ')' }">
							<router-link :to="{ path: '/list', query: { config_pc_id: 7 } }">
								<p>{{ $trans(types[6].title) }}</p>
								<p>{{ $trans(types[6].desc) }}</p>
								<div>
									Go >
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="idx-area">
				<div>
					<div class="area-special" v-for="(item, index) in navs" :key="item.id">
						<router-link :to="{ path: JSON.parse(item.nav_url).url }">
							<div class="special-txt divs">
								<span>{{ $trans(item.nav_title) }}</span>
								<p v-if="item.nav_title2" :class="[(index + 1) % 2 == 0 ? 'txt-smll' : '']">{{
										$trans(item.nav_title2)
								}}
								</p>
							</div>
							<div class="special-img" v-if="item.desc1 != ''">
								<div v-if="item.desc1 != ''">
									<div>
										<p class="special-img-p">{{ $trans(item.desc1) }}></p>
									</div>
									<p>{{ $trans(item.desc2) }}</p>
								</div>
								<img :src="$img(item.img2)" alt="" v-if="item.desc1 != ''"></img>
							</div>
							<div class="special-img" v-if="item.desc1 == ''">
								<img :src="$img(item.img1)" alt="">
								<img :src="$img(item.img2)" alt="">
							</div>
						</router-link>

					</div>
				</div>
			</div>
		</div>
		<!-- 商品 -->
		<div class="idx-location" v-if="pc_layout">
			<div>
				<div>
					<div class="location-title">
						<div>
							<span>{{ $trans(types[0].title) }}</span>
							<span v-if="types[0].desc"> |</span>
							<span>
								{{ $trans(types[0].desc) }}
							</span>
						</div>
						<router-link :to="{ path: '/list', query: { config_pc_id: 1 } }" class="notice-more">
							{{ $lang('更多') }}
							<i class="iconfont iconarrow-right"></i>
						</router-link>
					</div>

					<div class="location-modity" :class="[types[0].img2 == '' ? 'location-image' : '']">
						<div class="modity-big" :style="{ 'backgroundImage': 'url(' + $img(types[0].img2) + ')' }"
							v-if="types[0].img2">
							<router-link :to="{ path: '/list', query: { config_pc_id: 1 } }">
								<div>
									<span>
										{{ $trans(types[0].title) }}{{ $lang('专区') }}
									</span>
									<span>></span>
								</div>
							</router-link>

						</div>
						<div class="modity-small" :class="[types[0].img2 == '' ? 'small-image' : '']">
							<div v-for="(item, index) in ficationList" :key="index"
								@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
								<img :src="$img(item.goods_image)" alt="">
								<div class="small-txt">
									<span>{{ $trans(item.goods_name) }}</span>
									<span>{{ $trans($trans(item.price)) }}</span>
									<span>{{ $lang('已售') }}{{ $trans($trans(item.sale_num)) }}{{ $lang('件') }}</span>
								</div>
							</div>
							<div v-if="ficationList.length == 0" class="small-available">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="idx-location-two">
				<div>
					<div class="location-title">
						<div>
							<span>{{ $trans(types[1].title) }}</span>
							<span v-if="types[1].desc"> |</span>
							<span>
								{{ $trans(types[1].desc) }}
							</span>
						</div>
						<router-link :to="{ path: '/list', query: { config_pc_id: 2 } }" class="notice-more">
							{{ $lang('更多') }}
							<i class="iconfont iconarrow-right"></i>
						</router-link>
					</div>

					<div class="location-modity" :class="[types[1].img2 == '' ? 'location-image' : '']">
						<div class="modity-big" :style="{ 'backgroundImage': 'url(' + $img(types[1].img2) + ')' }"
							v-if="types[1].img2">
							<router-link :to="{ path: '/list', query: { config_pc_id: 2 } }">
								<div>
									<span>
										{{ $trans(types[1].title) }}{{ $lang('专区') }}
									</span>
									<span>></span>
								</div>
							</router-link>
						</div>
						<div class="modity-small" :class="[types[1].img2 == '' ? 'small-image' : '']">
							<div v-for="(item, index) in ficatitwList" :key="index"
								@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
								<img :src="$img(item.goods_image)" alt="">
								<div class="small-txt">
									<span>{{ $trans(item.goods_name) }}</span>
									<span>{{ $trans(item.price) }}</span>
									<span>{{ $lang('已售') }}{{ $trans(item.sale_num) }}件</span>
								</div>
							</div>
							<div v-if="ficatitwList.length == 0" class="small-available">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div>
					<div class="location-title">
						<div>
							<span>{{ $trans(types[2].title) }}</span>
							<span v-if="types[2].desc"> |</span>
							<span>
								{{ $trans(types[2].desc) }}
							</span>
						</div>
						<router-link :to="{ path: '/list', query: { config_pc_id: 3 } }" class="notice-more">
							{{ $lang('更多') }}
							<i class="iconfont iconarrow-right"></i>
						</router-link>
					</div>

					<div class="location-modity" :class="[types[2].img2 == '' ? 'location-image' : '']">
						<div class="modity-big" :style="{ 'backgroundImage': 'url(' + $img(types[2].img2) + ')' }"
							v-if="types[2].img2">
							<router-link :to="{ path: '/list', query: { config_pc_id: 3 } }">
								<div>
									<span>
										{{ $trans(types[2].title) }}{{ $lang('专区') }}
									</span>
									<span>></span>
								</div>
							</router-link>

						</div>
						<div class="modity-small" :class="[types[2].img2 == '' ? 'small-image' : '']">
							<div v-for="(item, index) in ficatithList" :key="index"
								@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
								<img :src="$img(item.goods_image)" alt="">
								<div class="small-txt">
									<span>{{ $trans(item.goods_name) }}</span>
									<span>{{ $trans(item.price) }}</span>
									<span>{{ $lang('已售') }}{{ $trans(item.sale_num) }}件</span>
								</div>
							</div>
							<div v-if="ficatithList.length == 0" class="small-available">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="idx-location-two">
				<div>
					<div class="location-title">
						<div>
							<span>{{ $trans(types[3].title) }}</span>
							<span v-if="types[3].desc"> |</span>
							<span>
								{{ $trans(types[3].desc) }}
							</span>
						</div>
						<router-link :to="{ path: '/list', query: { config_pc_id: 4 } }" class="notice-more">
							{{ $lang('更多') }}
							<i class="iconfont iconarrow-right"></i>
						</router-link>
					</div>

					<div class="location-modity" :class="[types[3].img2 == '' ? 'location-image' : '']">
						<div class="modity-big" :style="{ 'backgroundImage': 'url(' + $img(types[3].img2) + ')' }"
							v-if="types[3].img2">
							<router-link :to="{ path: '/list', query: { config_pc_id: 4 } }">
								<div>
									<span>
										{{ $trans(types[3].title) }}{{ $lang('专区') }}
									</span>
									<span>></span>
								</div>
							</router-link>
						</div>
						<div class="modity-small" :class="[types[3].img2 == '' ? 'small-image' : '']">
							<div v-for="(item, index) in ficatifoList" :key="index"
								@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">

								<img :src="$img(item.goods_image)" alt="">
								<div class="small-txt">
									<span>{{ $trans(item.goods_name) }}</span>
									<span>{{ $trans(item.price) }}</span>
									<span>{{ $lang('已售') }}{{ $trans(item.sale_num) }}件</span>
								</div>

							</div>
							<div v-if="ficatifoList.length == 0" class="small-available">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div>
					<div class="location-title">
						<div>
							<span>{{ $trans(types[4].title) }}</span>
							<span v-if="types[4].desc"> |</span>
							<span>
								{{ $trans(types[4].desc) }}
							</span>
						</div>
						<router-link :to="{ path: '/list', query: { config_pc_id: 5 } }" class="notice-more">
							{{ $lang('更多') }}
							<i class="iconfont iconarrow-right"></i>
						</router-link>
					</div>

					<div class="location-modity" :class="[types[4].img2 == '' ? 'location-image' : '']">
						<div class="modity-big" :style="{ 'backgroundImage': 'url(' + $img(types[4].img2) + ')' }"
							v-if="types[4].img2">
							<router-link :to="{ path: '/list', query: { config_pc_id: 5 } }">
								<div>
									<span>
										{{ $trans(types[4].title) }}{{ $lang('专区') }}
									</span>
									<span>></span>
								</div>
							</router-link>

						</div>
						<div class="modity-small" :class="[types[4].img2 == '' ? 'small-image' : '']">
							<div v-for="(item, index) in ficatifiList" :key="index"
								@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">

								<img :src="$img(item.goods_image)" alt="">
								<div class="small-txt">
									<span>{{ $trans(item.goods_name) }}</span>
									<span>{{ $trans(item.price) }}</span>
									<span>{{ $lang('已售') }}{{ $trans(item.sale_num) }}件</span>
								</div>

							</div>
							<div v-if="ficatifiList.length == 0" class="small-available">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="idx-location-two">
				<div>
					<div class="location-title">
						<div>
							<span>{{ $trans(types[5].title) }}</span>
							<span v-if="types[5].desc"> |</span>
							<span>
								{{ $trans(types[5].desc) }}
							</span>
						</div>
						<router-link :to="{ path: '/list', query: { config_pc_id: 6 } }" class="notice-more">
							{{ $lang('更多') }}
							<i class="iconfont iconarrow-right"></i>
						</router-link>
					</div>

					<div class="location-modity" :class="[types[5].img2 == '' ? 'location-image' : '']">
						<div class="modity-big" :style="{ 'backgroundImage': 'url(' + $img(types[5].img2) + ')' }"
							v-if="types[5].img2">
							<router-link :to="{ path: '/list', query: { config_pc_id: 6 } }">
								<div>
									<span>
										{{ $trans(types[5].title) }}{{ $lang('专区') }}
									</span>
									<span>></span>
								</div>
							</router-link>
						</div>
						<div class="modity-small" :class="[types[5].img2 == '' ? 'small-image' : '']">
							<div v-for="(item, index) in ficatisixList" :key="index"
								@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">

								<img :src="$img(item.goods_image)" alt="">
								<div class="small-txt">
									<span>{{ $trans(item.goods_name) }}</span>
									<span>{{ $trans(item.price) }}</span>
									<span>{{ $lang('已售') }}{{ $trans(item.sale_num) }}件</span>
								</div>

							</div>
							<div v-if="ficatisixList.length == 0" class="small-available">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<div>
					<div class="location-title">
						<div>
							<span>{{ $trans(types[6].title) }}</span>
							<span v-if="types[6].desc"> |</span>
							<span>
								{{ $trans(types[6].desc) }}
							</span>
						</div>
						<router-link :to="{ path: '/list', query: { config_pc_id: 7 } }" class="notice-more">
							{{ $lang('更多') }}
							<i class="iconfont iconarrow-right"></i>
						</router-link>
					</div>

					<div class="location-modity" :class="[types[6].img2 == '' ? 'location-image' : '']">
						<div class="modity-big" :style="{ 'backgroundImage': 'url(' + $img(types[6].img2) + ')' }"
							v-if="types[6].img2">
							<router-link :to="{ path: '/list', query: { config_pc_id: 7 } }">
								<div>
									<span>
										{{ $trans(types[6].title) }}{{ $lang('专区') }}
									</span>
									<span>></span>
								</div>
							</router-link>
						</div>
						<div class="modity-small" :class="[types[6].img2 == '' ? 'small-image' : '']">
							<div v-for="(item, index) in ficatisevList" :key="index"
								@click="$router.pushToTab({ path: '/sku-' + item.sku_id })">

								<img :src="$img(item.goods_image)" alt="">
								<div class="small-txt">
									<span>{{ $trans(item.goods_name) }}</span>
									<span>{{ $trans(item.price) }}</span>
									<span>{{ $lang('已售') }}{{ $trans(item.sale_num) }}件</span>
								</div>
							</div>
							<div v-if="ficatisevList.length == 0" class="small-available">
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="idx-service" v-if="pc_layout">
			<div>
				<div class="service-title">
					<div>
						<span>{{ $lang('热门资讯') }}</span>
					</div>
					<div>
						<router-link to="/cms/notice" class="notice-more">
							{{ $lang('更多') }}
							<i class="iconfont iconarrow-right"></i>
						</router-link>
					</div>
				</div>
				<div class="service-content">
					<ul>
						<li v-for="(item, index) in noticeList" :key="index">
							<div class="notice-title" @click="$router.pushToTab({ path: '/cms/notice-' + item.id })">{{
									$trans(item.title)
							}}</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import floorStyle1 from './components/floor-style-1.vue';
import floorStyle2 from './components/floor-style-2.vue';
import floorStyle3 from './components/floor-style-3.vue';
import index from './_index/index.js';
export default {
	name: 'index',
	components: {
		floorStyle1,
		floorStyle2,
		floorStyle3
	},
	mixins: [index]
};
</script>

<style lang="scss" scoped>
@import './_index/index.scss';
</style>

<style lang="scss" scoped>
.count-down {
	span {
		display: inline-block;
		width: 22px;
		height: 22px;
		line-height: 22px;
		text-align: center;
		background: #383838;
		color: #ffffff;
		border-radius: 2px;
	}
}

.more {
	width: 1200px;
	margin: auto;
	display: flex;
	justify-content: flex-end;

	a {
		color: #ff547b
	}
}

.goods-info {
	width: 1200px;
	margin: 5px auto 40px;
	display: flex;
	flex-wrap: wrap;

	.item {
		width: 202px;
		margin: 10px 20px 0 0;
		border: 1px solid #eeeeee;
		padding: 10px;
		position: relative;

		&:nth-child(5 n) {
			margin-right: initial !important;
		}

		&:hover {
			border: 1px solid $base-color;
		}

		.img-wrap {
			width: 198px;
			height: 198px;
			cursor: pointer;
		}

		.goods-name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			cursor: pointer;

			&:hover {
				color: $base-color;
			}
		}

		.price-wrap {
			display: flex;
			align-items: center;

			.price {
				display: flex;
				color: $base-color;
				font-size: $ns-font-size-lg;

				p {
					font-size: $ns-font-size-base;
					display: flex;
					align-items: flex-end;
				}
			}

			.market-price {
				color: #838383;
				text-decoration: line-through;
				margin-left: 10px;
			}
		}

		.sale-num {
			display: flex;
			color: #838383;

			p {
				color: #4759a8;
			}
		}

		.saling {
			display: flex;
			font-size: $ns-font-size-sm;
			line-height: 1;

			.free-shipping {
				background: $base-color;
				color: #ffffff;
				padding: 3px 4px;
				border-radius: 2px;
				margin-right: 5px;
			}

			.promotion-type {
				color: $base-color;
				border: 1px solid $base-color;
				display: flex;
				align-items: center;
				padding: 1px;
			}
		}
	}
}
</style>
